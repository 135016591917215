.popup {
  position: fixed;
  top: 92px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding-bottom: 20px;
}

.popup-inner {
  background: black;
  padding: 50px;
  border-radius: 10px;
  max-width: 1100px;
  max-height: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}

.popup-inner-2 {
  background: white;
  padding: 0px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




/* Custom styles for small screens (max-width: 1023px) */
@media (max-width: 1023px) {
  .toast-message {
    font-size: 32px;
    padding: 30px 20px;
  }

  /** Used to position the icon **/
  .Toastify__toast-icon {
    height: 40px;
    width: 40px;
    padding-right: 10px;
  }

  .loading-spinner {
    width: 100px;
    /* Adjust the width to your desired size */
    height: 100px;
    /* Adjust the height to your desired size */
    /* Other styling properties for your spinner */
  }
}

.Toastify__toast-container--top-right {
  width: auto;
}